<template>
    <div class="wverify">
        <div class="button" style="margin-top:120px;">
            <button @click="onClickSingle">단일 검증</button>
        </div>
        <div class="button" style="margin-top:32px;">
            <button @click="onClickMulti">다자간 검증</button>
        </div>
    </div>
</template>

<script>
//import { mapGetters } from "vuex";

export default
{
    name: 'app-verify',
    data()
    {
        return {
            
        };
    },
    methods:
    {
        onClickSingle()
        {
            this.$router.push('/verify/single');
        },
        onClickMulti()
        {
            this.$router.push('/verify/multi');
        }
    }
}
</script>

<style scoped>
.button { display: flex; justify-content: center; }
button { width: 200px; }
</style>