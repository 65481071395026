<template>
    <div class="wmulti">
        <div class="inputbox">
            <div class="label">Participants</div>
            <div class="input"><input type="text" v-model="verifycode[0]"></div>
            <button @click="onClickPaste(0)">Paste</button>
            <button @click="onClickDelete(0)" disabled>-</button>
        </div>
        <div class="inputbox">
            <div class="label"></div>
            <div class="input"><input type="text" v-model="verifycode[1]"></div>
            <button @click="onClickPaste(1)">Paste</button>
            <button @click="onClickDelete(1)" disabled>-</button>
        </div>
        <div v-for="n in (verifycode.length-2)" :key="n" class="inputbox">
            <div class="label"></div>
            <div class="input"><input type="text" v-model="verifycode[n+1]"></div>
            <button @click="onClickPaste(n+1)">Paste</button>
            <button @click="onClickDelete(n+1)">-</button>
        </div>
        <div class="inputbox" style="justify-content:center;">
            <button @click="onClickAdd()">Add Participant</button>
        </div>

        <div style="display:flex; justify-content:center; margin-top: 50px;">
            <button @click="onClickVerify" style="padding-left: 30px; padding-right: 30px;">Verify</button>
        </div>
        <div class="result" v-html="result"></div>
    </div>
</template>

<script>

export default
{
    name: 'verify-multi',
    data()
    {
        return {
            verifycode: ['', ''],
            contractInfo: [],
            result: ''
        };
    },
    methods:
    {
        async onClickPaste(index)
        {
            try
            {
                const text = await navigator.clipboard.readText();
                this.verifycode[index] = text;
            }
            catch ( err )
            {
                console.error(err);
            }
        },
        onClickAdd()
        {
            this.verifycode.push('');
        },
        onClickDelete(index)
        {
            this.verifycode.splice(index, 1);
        },
        getContractInfo(index, code)
        {
            const curtime = Math.floor(Date.now() / 1000);
            const contractInfo = this.$utils.parseVerifyCode(code);
            if ( contractInfo == null )
            {
                this.result = `Invalid Verify Code - ${(index+1)}!!`;
                return null;
            }

            if ( contractInfo.curtime + 1800 < curtime )
            {
                this.result = `Verify Code Expired - ${(index+1)}!!`;
                return null;
            }

            return contractInfo;
        },
        async onClickVerify()
        {
            var i;

            if ( this.verifycode.length < 2 ) return;
            for ( i = 0 ; i < this.verifycode.length ; i++ )
            {
                if ( this.verifycode[i].length == 0 )
                    return;
            }

            try
            {
                this.contractInfo = [];
                for ( i = 0 ; i < this.verifycode.length ; i++ )
                {
                    this.contractInfo[i] = this.getContractInfo(i, this.verifycode[i]);
                    if ( this.contractInfo[i] == null )
                        return;
                }
                console.log(this.contractInfo[0]);

                for ( i = 1 ; i < this.contractInfo.length ; i++ )
                {
                    if ( this.contractInfo[0].contract_id != this.contractInfo[i].contract_id
                        || this.contractInfo[0].ipfsCID != this.contractInfo[i].ipfsCID )
                    {
                        this.result = `It's not a single contract`;
                        return;
                    }
                }

                const abi = this.$blockchain.getABI(this.contractInfo[0].chain.contract);
                const cryptoKey = await this.$blockchain.getCryptoKey(this.contractInfo[0].participants);
                const inputdata = await this.$blockchain.getTransactionInputData(this.contractInfo[0].chain.name, this.contractInfo[0].chain.txid, abi);

                const ipfsCID = inputdata.params.dataURL.split('/').pop();
                if ( inputdata.params.contractId != this.contractInfo[0].contract_id || ipfsCID != this.contractInfo[0].ipfsCID )
                {
                    this.result = 'Invalid Verify Code[3]!!';
                    return;
                }

                const fetchInfo = await this.$blockchain.fetchInfoFromContract(this.contractInfo[0].contract_id, abi, this.contractInfo[0].chain);
                if ( fetchInfo[2] != 1 )
                {
                    this.result = 'Incorrect Contract Status!!';
                    return;
                }

                const cryptoData = await this.$blockchain.fetchDataFromIPFS(fetchInfo[1]);
                const zipData = await this.$blockchain.decryptData(cryptoData, cryptoKey);
                const orginalData = await this.$blockchain.unzipData(zipData);
                const contractData = JSON.parse(orginalData);
                console.log(contractData);

                if ( contractData.contract_id != this.contractInfo[0].contract_id
                    || (contractData.create_time + contractData.complete_time) != this.contractInfo[0].time )
                {
                    this.result = 'Invalid Contract Contents[1]!!';
                    return;
                }

                if ( contractData.participants.length != this.contractInfo.length )
                {
                    this.result = 'Invalid Contract Contents[2]!!';
                    return;
                }

                var contractInfo_emails = [];
                for ( i = 0 ; i < this.contractInfo.length ; i++ )
                {
                    contractInfo_emails.push(this.contractInfo[i].usermail);
                }
                contractInfo_emails.sort((a, b) => (a > b ? -1 : 1));

                var contractData_emails = [];
                for ( i = 0 ; i < contractData.participants.length ; i++ )
                {
                    contractData_emails.push(contractData.participants[i].email);
                }
                contractData_emails.sort((a, b) => (a > b ? -1 : 1));

                if ( contractInfo_emails.join() != contractData_emails.join() )
                {
                    this.result = 'Invalid Contract Contents[3]!!';
                    return;
                }


                delete contractData.use_phone_cert;
                for ( i = 0 ; i < contractData.files.length ; i++ ) delete contractData.files[i].data;
                for ( i = 0 ; i < contractData.participants.length ; i++ ) delete contractData.participants[i].sign_file;
                this.result = '[Verified]<br><br>' + '<pre>' + JSON.stringify(contractData, null, 2) + '</pre>';
            }
            catch ( err )
            {
                console.error(err);
                this.result = err.message;
            }
        }
    },
    created()
    {
        const cookie = this.$utils.getCookie('queryString');
        if ( cookie )
        {
            const curtime = Math.floor(Date.now() / 1000);
			const contractInfo = this.$utils.parseVerifyCode(cookie);

            if ( contractInfo.curtime + 1800 >= curtime )
            {
                this.verifycode[0] = cookie;
            }
        }
    }
}
</script>

<style scoped>
.wmulti .title { line-height: 32px; margin-bottom: 10px; }
.wmulti .inputbox { display: flex; align-items: center; margin-bottom:10px; }
.wmulti .inputbox .label { width: 100px; text-align: right; margin-right: 10px; }
.wmulti .inputbox .input { border: 1px solid black; padding: 10px; width: 500px; }
.wmulti .inputbox button { padding-left: 12px; padding-right: 12px; margin-left: 8px; height: 40px; }
.wmulti .result { width: 100%; min-height: 300px; border: 1px solid black; margin-top: 30px; padding: 10px; }
</style>
