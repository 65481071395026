import { createApp } from 'vue'
import App from './App.vue'

import mitt from 'mitt'
import router from './router'
import blockchain from './blockchain'
import utils from './utils'

import './assets/style.css'

const emitter = mitt();
const app = createApp(App)

app.config.globalProperties.$emitter = emitter;
app.config.globalProperties.$blockchain = blockchain;
app.config.globalProperties.$utils = utils;

app.use(router)
app.mount('#app')