import Web3 from "web3";
import md5 from 'js-md5';
import pako from 'pako';

import utils from '@/utils'
import chains from '@/assets/chains.json';
import abis from '@/assets/abis.json';

const blockchain =
{
    getABI: function(contract)
    {
        try
        {
            var i, j;

            for ( i = 0 ; i < abis.length ; i++ )
            {
                for ( j = 0 ; j < abis[i].contracts.length ; j++ )
                {
                    if ( abis[i].contracts[j].toLowerCase() == contract.toLowerCase() )
                    {
                        return abis[i].abi;
                    }
                }
            }

            return null;
        }
        catch ( err )
        {
            console.error(err);
            throw new Error("Not Found Contract Address");
        }
    },
    getTransactionInputData: async function(name, txid, abi)
    {
        try
        {
            const web3 = new Web3(chains[name]);
            const transaction = await web3.eth.getTransaction(txid);
  
            if ( transaction && transaction.input )
            {
                const inputData = transaction.input;
                const methodId = inputData.slice(0, 10);
                const params = inputData.slice(10);

                for ( var i = 0 ; i < abi.length ; i++ )
                {
                    if ( methodId == web3.eth.abi.encodeFunctionSignature(abi[i]) )
                    {
                        const decodedParams = web3.eth.abi.decodeParameters(abi[i].inputs, params);
                        return {func: abi[i].name, params: decodedParams};
                    }
                }

                return null;
            }
            else
            {
                throw new Error("Not Found Transaction");
            }
        }
        catch ( err )
        {
            console.error(err);
            throw new Error("Invalid Transaction ID!!");
        }
    },
    getCryptoKey: async function(participants)
    {
        var nameEmailList = [];
        for ( var i = 0 ; i < participants.length ; i++ )
        {
            var domain = participants[i].email.split("@")[1];
            nameEmailList.push(participants[i].name + "*#$" + domain);
        }
        nameEmailList.sort((a, b) => (a > b ? -1 : 1));

        try
        {
            const plaintext = nameEmailList.join("!&");

            const msgBuffer = new TextEncoder().encode(plaintext);
            const hashBuffer = await crypto.subtle.digest('SHA-256', msgBuffer);
            const sha256Hash = new Uint8Array(hashBuffer);

            const sha256Hex = Array.from(sha256Hash).map(b => b.toString(16).padStart(2, '0')).join('');
            const md5Hash = md5(sha256Hex);

            const combinedHash = sha256Hex + md5Hash;
            const result = combinedHash.slice(0, 64);

            return result;
        }
        catch ( err )
        {
            console.error(err);
            throw new Error("Invalid Verify Code[2]!!");
        }
    },
    fetchInfoFromContract: async function(contract_id, contractABI, chaininfo)
    {
        try
        {
            const web3 = new Web3(chains[chaininfo.name]);
            const contract = new web3.eth.Contract(contractABI, chaininfo.contract);
            const info = await contract.methods.getContractInfo(contract_id).call();
            return info;
        }
        catch ( err )
        {
            console.error(err);
            throw new Error("Invalid Verify Code[2]!!");
        }
    },
    fetchDataFromIPFS: async function(ipfsURL)
    {
        try
        {
            const response = await fetch(ipfsURL);

            if ( !response.ok )
            {
                console.log(`HTTP error status: ${response.status}`);
                return null;
            }

            const contentLength = response.headers.get('Content-Length');
            if ( !contentLength )
            {
                console.log('Unable to retrieve content length');
                return null;
            }

            //const totalBytes = parseInt(contentLength, 10);
            const reader = response.body.getReader();
            const decoder = new TextDecoder('utf-8');

            //var receivedBytes = 0;
            var result = '';

            // eslint-disable-next-line
            while ( true )
            {
                const { done, value } = await reader.read();
                if ( done ) { break; }

                //receivedBytes += value.length;
                result += decoder.decode(value, { stream: true });

                //const progress = (receivedBytes / totalBytes) * 100;
                //this.process.message = `Fetching File Data from IPFS: ${receivedBytes} bytes (${progress.toFixed(2)}%)`;
            }

            return result;
        }
        catch ( err )
        {
            console.error(err);
            throw new Error("Invalid Contract File[1]!!");
        }
    },
    decryptData: async function(ciphertextBase64, keyHex)
    {
        try
        {
            const keyBytes = utils.hexStringToArrayBuffer(keyHex);
            const ciphertextBytes = new Uint8Array(utils.base64ToArrayBuffer(ciphertextBase64));

            const nonceSize = 12;
            const nonce = ciphertextBytes.slice(0, nonceSize);
            const ciphertext = ciphertextBytes.slice(nonceSize);

            const key = await crypto.subtle.importKey('raw', keyBytes, {name: 'AES-GCM'}, false, ['decrypt']);
            const decryptedBuffer = await crypto.subtle.decrypt({name: 'AES-GCM', iv: nonce}, key, ciphertext);

            return decryptedBuffer;
        }
        catch (err)
        {
            console.error(err);
            throw new Error("Invalid Contract File[2]!!");
        }
    },
    unzipData: async function(zipData)
    {
        try
        {
            const decompressedData = pako.inflate(zipData, { to: 'string' });
            return decompressedData;
        }
        catch (err)
        {
            console.error(err);
            throw new Error("Invalid Contract File[3]!!");
        }
    },
};

export default blockchain
