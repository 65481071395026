import { createRouter, createWebHistory } from 'vue-router'

import Verify from './pages/Verify.vue';
import VerifySingle from './pages/VerifySingle.vue';
import VerifyMulti from './pages/VerifyMulti.vue';

const routes = [
    {
        path: '/verify',
        component: Verify
    },
    {
        path: '/verify/single',
        component: VerifySingle
    },
    {
        path: '/verify/multi',
        component: VerifyMulti
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

router.afterEach((to, from) =>
{
    const toPath = to.path.split('/');
    const fromPath = from.path.split('/');

    if ( toPath[1] === fromPath[1] )
    {
        to.meta.transition = toPath.length < fromPath.length ? 'slide-left' : 'slide-right';
    }
    else
    {
        to.meta.transition = 'fade';
    }
})

export default router