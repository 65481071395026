<template>
    <div class="container">
        <router-view v-slot="{ Component, route }">
            <transition :name="route.meta.transition">
                <component :is="Component" />
            </transition>
        </router-view>
    </div>
</template>

<script>
export default
{
    name: 'app',
    data()
    {
        return {
            
        };
    },
    methods:
    {
        checkQueryString(queryStr)
        {
            try
            {
				if ( queryStr != null && queryStr.length > 0 )
				{
					const curtime = Math.floor(Date.now() / 1000);
					const contractInfo = this.$utils.parseVerifyCode(queryStr);
					console.log(contractInfo);

					if ( contractInfo.curtime + 1800 < curtime )
					{
						alert('Verify Code Expired!!');
					}
				}
            }
            catch (err)
            {
                console.error(err);
            }
			finally
			{
				this.$router.push('/verify');
			}
        },  
    },
    created()
    {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const q = urlParams.get('q');

		if ( q != null && q.length > 0 )
		{
			this.checkQueryString(q);
			this.$utils.setCookie('queryString', q, 15);
		}
		else
		{
			const cookie = this.$utils.getCookie('queryString');
			this.checkQueryString(cookie);
		}
    }
}
</script>

<style scoped>

</style>