const utils =
{
    parseVerifyCode: (code) =>
    {
        try
        {
            const plane = utils.base64ToUnicode(code);
            const json = JSON.parse(plane.slice(5));
            
            return {
                chain: {
                    name: json.b.p,
                    contract: json.b.c,
                    txid: json.b.b,
                },
                participants: json.p,
                contract_id: json.c,
                ipfsCID: json.d,
                time: json.t,
                curtime: json.nt,
                usermail: json.m
            };
        }
        catch ( err )
        {
            console.error(err);
            return null;
        }
    },
    hexStringToArrayBuffer: (hexString) =>
    {
        const bytes = new Uint8Array(hexString.length / 2);
        for ( var i = 0 ; i < bytes.length ; i++ ) bytes[i] = parseInt(hexString.substr(i * 2, 2), 16);

        return bytes;
    },
    base64ToArrayBuffer: (base64) =>
    {
        const binaryString = atob(base64);
        const len = binaryString.length;
        const bytes = new Uint8Array(len);
        for ( var i = 0 ; i < len ; i++ ) bytes[i] = binaryString.charCodeAt(i);

        return bytes.buffer;
    },
    base64ToBlob: (base64, mimeType) =>
    {
        const byteCharacters = atob(base64);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) byteNumbers[i] = byteCharacters.charCodeAt(i);
        const byteArray = new Uint8Array(byteNumbers);
        return new Blob([byteArray], { type: mimeType });
    },
    decodeBase64ToUtf8: (base64) =>
    {
        const binaryString = atob(base64);
        const binaryLen = binaryString.length;
        const bytes = new Uint8Array(binaryLen);
        for (let i = 0; i < binaryLen; i++) bytes[i] = binaryString.charCodeAt(i);
        const decoder = new TextDecoder('utf-8');
        return decoder.decode(bytes);
    },
    unicodeToBase64: (str) =>
    {
        return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function(match, p1) {
            return String.fromCharCode('0x' + p1);
        }));
    },
    base64ToUnicode: (str) =>
    {
        return decodeURIComponent(Array.prototype.map.call(atob(str), function(c) {
            return '%' + c.charCodeAt(0).toString(16).padStart(2, '0');
        }).join(''));
    },
    setCookie: (name, value, minutes) =>
    {
        const date = new Date();
        date.setTime(date.getTime() + (minutes * 60 * 1000));
        const expires = "expires=" + date.toUTCString();
        document.cookie = name + "=" + value + ";" + expires + ";path=/";
    },
    getCookie: (name) =>
    {
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${name}=`);

        if ( parts.length === 2 )
            return parts.pop().split(';').shift();

        return null;
    },
};

export default utils
