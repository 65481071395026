<template>
    <div class="wsingle">
        <div class="title">Verify Code</div>
        <div class="inputbox">
            <div class="input"><input type="text" v-model="verifycode"></div>
            <button @click="onClickPaste">Paste</button>
        </div>
        <div style="display:flex; justify-content:center; margin-top: 50px;">
            <button @click="onClickVerify" style="padding-left: 30px; padding-right: 30px;">Verify</button>
        </div>
        <div class="result" v-html="result"></div>
    </div>
</template>

<script>

export default
{
    name: 'verify-single',
    data()
    {
        return {
            verifycode: '',
            result: ''
        };
    },
    methods:
    {
        async onClickPaste()
        {
            try
            {
                const text = await navigator.clipboard.readText();
                this.verifycode = text;
            }
            catch ( err )
            {
                console.error(err);
            }
        },
        async onClickVerify()
        {
            if ( this.verifycode.length == 0 ) return;

            try
            {
                var i;

                const curtime = Math.floor(Date.now() / 1000);
                const contractInfo = this.$utils.parseVerifyCode(this.verifycode);
                if ( contractInfo == null )
                {
                    this.result = 'Invalid Verify Code[1]!!';
                    return;
                }
                console.log(contractInfo);

                if ( contractInfo.curtime + 1800 < curtime )
                {
                    this.result = 'Verify Code Expired!!';
                    return;
                }

                const abi = this.$blockchain.getABI(contractInfo.chain.contract);
                const cryptoKey = await this.$blockchain.getCryptoKey(contractInfo.participants);
                const inputdata = await this.$blockchain.getTransactionInputData(contractInfo.chain.name, contractInfo.chain.txid, abi);

                const ipfsCID = inputdata.params.dataURL.split('/').pop();
                if ( inputdata.params.contractId != contractInfo.contract_id || ipfsCID != contractInfo.ipfsCID )
                {
                    this.result = 'Invalid Verify Code[3]!!';
                    return;
                }

                const fetchInfo = await this.$blockchain.fetchInfoFromContract(contractInfo.contract_id, abi, contractInfo.chain);
                if ( fetchInfo[2] != 1 )
                {
                    this.result = 'Incorrect Contract Status!!';
                    return;
                }

                const cryptoData = await this.$blockchain.fetchDataFromIPFS(fetchInfo[1]);
                const zipData = await this.$blockchain.decryptData(cryptoData, cryptoKey);
                const orginalData = await this.$blockchain.unzipData(zipData);
                const contractData = JSON.parse(orginalData);
                console.log(contractData);

                if ( contractData.contract_id != contractInfo.contract_id
                    || (contractData.create_time + contractData.complete_time) != contractInfo.time )
                {
                    this.result = 'Invalid Contract Contents[1]!!';
                    return;
                }

                if ( contractData.participants.length != contractInfo.participants.length )
                {
                    this.result = 'Invalid Contract Contents[2]!!';
                    return;
                }

                var isSerach = false;
                for ( i = 0 ; i < contractData.participants.length ; i++ )
                {
                    if ( contractData.participants[i].email == contractInfo.usermail )
                    {
                        isSerach = true;
                        break;
                    }
                }
                if ( !isSerach )
                {
                    this.result = 'Invalid Contract Contents[3]!!';
                    return;
                }

                delete contractData.use_phone_cert;
                for ( i = 0 ; i < contractData.files.length ; i++ ) delete contractData.files[i].data;
                for ( i = 0 ; i < contractData.participants.length ; i++ ) delete contractData.participants[i].sign_file;
                this.result = '[Verified]<br><br>' + '<pre>' + JSON.stringify(contractData, null, 2) + '</pre>';
            }
            catch ( err )
            {
                console.error(err);
                this.result = err.message;
            }
        }
    },
    created()
    {
        const cookie = this.$utils.getCookie('queryString');
        if ( cookie )
        {
            const curtime = Math.floor(Date.now() / 1000);
			const contractInfo = this.$utils.parseVerifyCode(cookie);

            if ( contractInfo.curtime + 1800 >= curtime )
            {
                this.verifycode = cookie;
            }
        }
    }
}
</script>

<style scoped>
.wsingle .title { line-height: 32px; }
.wsingle .inputbox { display: flex; align-items: center; }
.wsingle .inputbox .input { border: 1px solid black; padding: 10px; width: 100%; }
.wsingle .inputbox button { padding-left: 12px; padding-right: 12px; margin-left: 8px; height: 40px; }
.wsingle .result { width: 100%; min-height: 300px; border: 1px solid black; margin-top: 30px; }
</style>
